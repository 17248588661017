<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<a-layout class="layout-default layout-sign-up" id="layout-default">

		<DefaultHeader></DefaultHeader>


		<a-layout-content>
			<div>

				<!-- Sign Up Image And Headings -->
				<div class="sign-up-header" style="background-image: url('/images/bg-signup.jpg')">
					<div class="content">
						<h1 class="mb-5">Sign Up</h1>
						<p class="text-lg">Create an account, and enjoy a full suite of tools.</p>
					</div>
				</div>
				<!-- / Sign Up Image And Headings -->

				<!-- Sign Up Form -->
				<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
					<template #title>
						<h5 class="font-semibold text-center">Register With email</h5>
					</template>
					<a-form ref="formRef" id="components-form-demo-normal-login" :model="form" class="login-form" @submit="handleSubmit">
						<a-form-item name="username" :rules="[{required: true, message: 'Please input your name!'}]" class="mb-10">
							<a-input v-model:value="form.username" placeholder="Username">
							</a-input>
						</a-form-item>
						<a-form-item name="mail" :rules="[{ type: 'email',required: true, message: 'Please enter the correct format of your email address!' }]" class="mb-10">
							<a-input-search v-model:value="form.mail" placeholder="Email">

								<template #enterButton>
									<a-button type="primary" @click="sendEmail" :disabled="timer" block
										class="login-form-button">
										{{ !timer ? 'GET CODE' : timer + 's' }}
									</a-button>
								</template>
							</a-input-search>
						</a-form-item>
						<a-form-item name="code" :rules="[{ required: true, message: 'Please input your email code!' }]" v-if="getCodeFalg" class="mb-5">
							<a-input v-model:value="form.code" placeholder="Email Code">
							</a-input>
						</a-form-item>
						<a-form-item name="password" :rules="[{ required: true, message: 'Please input your Password!' }]" class="mb-5">
							<a-input type="password" v-model:value="form.password" placeholder="Password"/>
						</a-form-item>
						<!-- <a-form-item name="inviteCode" :rules="[{required: true, message: 'Please input invitation code!' }] " class="mb-10">
							<a-input v-model:value="form.inviteCode" placeholder="Invitation Code">
								<template #suffix>
									
        							<a-tooltip title="Currently it is only for internal use within the laboratory. If you need an invitation code, please contact the administrator (1846001522@qq.com)">
          								<info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
									</a-tooltip>
								</template>
							</a-input>
						</a-form-item> -->
						<a-form-item class="mb-10">
							<a-checkbox name="remember"  v-model:checked="remember"></a-checkbox>
								I agree the <a href="/user/Privacy" class="font-bold text-dark">Privacy Policy</a> and <a href="/user/terms" class="font-bold text-dark">Terms of Service</a>
							
						</a-form-item>
						<a-form-item>
							<a-button v-if="getCodeFalg" :disabled="!remember" type="primary" block html-type="submit" class="login-form-button">
								SIGN UP
							</a-button>
							<a-button v-else type="primary" :disabled="!remember" @click="sendEmail" block class="login-form-button">
								GET CODE
							</a-button>
						</a-form-item>
					</a-form>
					<p class="font-semibold text-muted text-center">Already have an account? <router-link to="/sign-in"
							class="font-bold text-dark">Sign In</router-link></p>
				</a-card>
				<!-- / Sign Up Form -->

			</div>
		</a-layout-content>


		<DefaultFooter></DefaultFooter>
	</a-layout>
</template>

<script setup>

import DefaultHeader from '@/components/Headers/DefaultHeader';
import DefaultFooter from '@/components/Footers/DefaultFooter';
import { ref } from "vue";
// import { useStore } from "vuex";
import user from "@/api/modules/user";
import { to } from "@/utils/utils";
import { useRouter } from "vue-router";
import { message } from 'ant-design-vue';
// import {InfoCircleOutlined } from '@ant-design/icons-vue';
const router = useRouter()
// const store = useStore();
const formRef=ref(null)
const getCodeFalg = ref(false);
const form = ref({
	mail: '',
	username: '',
	code: '',
	password: '',
	// inviteCode:''
})
const timer = ref(false)
const code = ref(null)
const remember = ref(true)
const sendEmail = async () => {
	try {
     await formRef.value.validateFields(['Email']);
  } catch (errorInfo) {
    console.log('Failed:', errorInfo);
	if(errorInfo.errorFields.length>0)
		return;
  }
	
	const [err, res] = await to(
		user.sendMail(form.value.mail)
	);
	if (err) {
		return;
	}
	getCodeFalg.value = true;
	timer.value = 60;
	const time = setInterval(() => {
		timer.value--;
		if (timer.value == 0) {
			timer.value = false;
			clearInterval(time);
		}
	}, 1000)
	code.value = res.data;
}
const handleSubmit = async() => {
	try {
     await formRef.value.validateFields();
  } catch (errorInfo) {
    console.log('Failed:', errorInfo);
	if(errorInfo.errorFields.length>0)
		return;
  }
	
	const [err, res] = await to(
		user.enroll(form.value)
	);
	if (err) {
		
		message.error(res.message)
		return;
	}
	console.log(res);
	if(res.code==200){
		message.info('Register Success');
		router.push('/sign-in')
	}else{
		message.error(res.message)
	}
}
</script>

<style lang="scss">
.ant-input-search-button{
	border-radius: 0 6px 6px 0!important;
}
:where(.css-dev-only-do-not-override-1qb1s0s).ant-input-affix-wrapper{
	padding: 0px 11px;
}
</style>